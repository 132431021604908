import React from "react";

export const Logo = () => {
  return (
    <>
      <img
        className="headerLogo"
        src="./images/AIM-LOGO.png"
        alt="logo"
      />
      <div className="logo">
        <img src="./images/AIM-LOGO.png" alt="AIM" />
      </div>
    </>
  );
};
