import React from "react";
import "./footer.css";
import { String } from "../Moduls/String";
interface FooterProps {
  checkSectionVisibleImp: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Footer: React.FC<FooterProps> = (props) => {
  const listServices = String.services.map((listServices) => {
    return (
      <li>
        <a className="py-1 d-block">
          {listServices.title}
        </a>
      </li>
    );
  });
  return (
    <>
      <footer className={`footer`} style={{ marginTop: "20px" }}>
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-md-9 py-5">
              <div className="row">
                <div className="col-md-8">
                  <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                      <div className="row">
                        <div className="col-md-4 mb-md-0 mb-4">
                          <h2 className="footer-heading">
                            {String.FooterData.services}
                          </h2>
                          <ul className="list-unstyled">{listServices}</ul>
                        </div>
                        <div className="col-md-4 mb-md-0 "></div>
                        <div className="col-md-4 ">
                          <h2 className="footer-heading">
                            {String.FooterData.term}
                          </h2>
                          <ul className="list-unstyled">
                            <li
                              onClick={() => props.checkSectionVisibleImp(true)}
                              style={{cursor:"pointer"}}
                            >
                              <p  className="py-1 d-block">
                                {String.FooterData.termCondition}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerContact col-md-3 py-md-5 py-4 aside-stretch-right pl-lg-5">
              <h2 className="footer-heading">{String.FooterData.contactUs}</h2>
              <div className="col-md-5 col-lg-4 m-15px-tb">
                <div className="contact-name">
                  <h5>{String.ContactHeaderDetail.companyName}</h5>
                  <p>{String.ContactHeaderDetail.address1}</p>
                  <p>{String.ContactHeaderDetail.mobile1}</p>
                </div>

                <div className="">
                  <a href="https://a-im.at/">
                    {String.ContactHeaderDetail.website}
                  </a>
                  <p>{String.ContactHeaderDetail.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <p className="copyright" style={{display:"flex",flexDirection:"row"}}>
            {String.FooterData.copyRight} &copy; {new Date().getFullYear()}, All
            Right Reserved{" "}
            <p onClick={()=>{
              window.open("https://zsoftmedia.at/")
            }} style={{cursor:"pointer"}}>{String.FooterData.powredBy}</p>
          </p>
        </div>
      </footer>
    </>
  );
};
